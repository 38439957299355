import { render, staticRenderFns } from "./Drops.vue?vue&type=template&id=7191d144&scoped=true&"
import script from "./Drops.vue?vue&type=script&lang=js&"
export * from "./Drops.vue?vue&type=script&lang=js&"
import style0 from "./Drops.vue?vue&type=style&index=0&id=7191d144&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7191d144",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VLazy } from 'vuetify/lib/components/VLazy';
installComponents(component, {VLazy})


/* vuetify-loader */
import installDirectives from "!../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import ClickOutside from 'vuetify/lib/directives/click-outside'
installDirectives(component, {ClickOutside})
